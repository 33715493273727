import React from 'react';
import { hideSplashScreen } from '../../helpers/splashScreen';
import {setSessionStorageItem, getSessionStorageItem} from '../../helpers/utility';
export function LoginUser(phone, password,cb) {
    debugger;
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ phone: phone, pin:password })
    };
    let URL = window.APP.CONFIGURATION.API + 'login?app=1';
    fetch(URL, requestOptions)
        .then(response => response.json())
        .then(data => {
            debugger;
            if (data.status && data.data[0].user) {
                setSessionStorageItem('token', data.data[0].user.token);
                cb(data);
            }
            if (!data.status) {
                hideSplashScreen();
                cb(data);
                
            }
        });

}
export function ForgotPassword(email,cb) {
    debugger;
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: email })
    };
    let URL = window.APP.CONFIGURATION.API + 'forget-password';
    fetch(URL, requestOptions)
        .then(response => response.json())
        .then(data => {
            debugger;
            if (data) {
                cb(data);
            }
        });

}