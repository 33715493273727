import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Grid from '@material-ui/core/Grid';
import Input from '../../atoms/input/input';
import Btn from '../../atoms/button/button';
import DropDown from '../../atoms/dropdown/dropdown';
import { hideSplashScreen, showSplashScreen } from '../../../helpers/splashScreen';
import AppBarDashboard from '../../molecules/appBar/appBar';
import { createFeature, getAllStatus } from '../../../services/featurePackageService/featurePackageService';
import { showSuccessSnackbar } from '../../../stores/actions/snackBarActions';

const AddFeature = () => {
    const dispatch = useDispatch();
    const nevigate = useHistory();
    const [descriptionerror, setdescriptionerror] = useState("");
    const [descriptionvalidation, setdescriptionvalidation] = useState("");
    const [priceerror, setpriceerror] = useState("");
    const [pricevalidation, setpricevalidation] = useState("");
    const [valuestatus, setvaluestatus] = useState("");
    const [statusdropdown, setstatusdropdown] = useState([]);
    const formik = useFormik({
        initialValues: {
            description: '',
            price: '',
            status: ''
        },
        validationSchema: Yup.object({
        }),
        onSubmit: values => {
            showSplashScreen();
            createFeature(values, handlesuccessfeatureAdd)
            //alert(JSON.stringify(values, null, 2));
        },
    });
    const handlesuccessfeatureAdd = (data) => {
        if (data.status) {
            dispatch(showSuccessSnackbar("Feature Added successfully", "success"));
            nevigate.push("/features");
            hideSplashScreen();
        }
        else{
            dispatch(showSuccessSnackbar(data.message, "error"));
            hideSplashScreen();

        }

    }
    const handlechange = () => {

    }
    const handlechangestatus = (event) => {
        setvaluestatus(event.target.value);
    }
    const getStatusData = data => {
        setstatusdropdown(data);
    }
    useEffect(() => {
        getAllStatus(getStatusData);
    }, []);
    return (
        <AppBarDashboard>
            <h2>Sign In</h2>
            <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={5}>
                    <Grid item xs={6}>
                        <Input
                            {...formik.getFieldProps('description')}
                            id="description"
                            type="text"
                            labeltext="Description"
                            changehandler={handlechange}
                            helperText={descriptionerror}
                            error={descriptionvalidation}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Input
                            {...formik.getFieldProps('price')}
                            id="price"
                            type="number"
                            labeltext="Price"
                            changehandler={handlechange}
                            helperText={priceerror}
                            error={pricevalidation}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <DropDown
                            valuedropdown={valuestatus}
                            setvaluedropdown={setvaluestatus}
                            changehandler={handlechangestatus}
                            id="status"
                            labeltext="Status"
                            dropdownitems={statusdropdown}
                            {...formik.getFieldProps('status')}
                        />
                    </Grid>
                </Grid>
                <Btn
                    type="submit"
                    buttontext="Save"
                    clickhandler={handlechange}
                />
            </form>
        </AppBarDashboard>
    )

}

export default AddFeature;