import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useStyles } from './subscription.style';
import AppBarDashboard from '../../molecules/appBar/appBar';
import { GetSubscriptionList } from '../../../services/subscriptionService/subscriptionService';
import { showSuccessSnackbar } from '../../../stores/actions/snackBarActions';
import GenericTable from '../../atoms/table/table';

const SubscriptionGrid = () => {
    const classes = useStyles();
    const [allrows, setallrows] = React.useState([]);
    const dispatch = useDispatch();
    const nevigate = useHistory();
    const headCells = [
        { id: 'entity_id', numeric: false, disablePadding: true, label: 'Entity Id' },
        { id: 'name', numeric: false, disablePadding: true, label: 'Name' },
        { id: 'entity_city', numeric: false, disablePadding: true, label: 'Entity City' },
        { id: 'vat_id', numeric: false, disablePadding: true, label: 'VAT Id' },
        { id: 'cr_number', numeric: false, disablePadding: true, label: 'Number' },
        { id: 'payment_status', numeric: false, disablePadding: true, label: 'Payment Status' },
        { id: 'subscription_status', numeric: false, disablePadding: true, label: 'Subscription Status' },
        { id: 'subscription_period', numeric: false, disablePadding: true, label: 'Subscription Period' },
        { id: 'subscription_date', numeric: false, disablePadding: true, label: 'Subscription Date' },
        { id: 'agent_name', numeric: false, disablePadding: true, label: 'Agent Name' },
        { id: 'agent_phone', numeric: false, disablePadding: true, label: 'Agent Phone' },
    ];
    const CallbackData = data => {
        setallrows(data.data);
    }
    const editpre = (event, selected) => {
        debugger;
        if (selected.length > 1) {
            dispatch(showSuccessSnackbar("Please select only one Subscription", "warning"));
        }
        else if (selected.length === 1) {
            //nevigate.push('addrolesprivileges?id=' + selected.toString());
        }
    }
    const openDetails = (event, selected) => {
        debugger;
        if (selected.length > 1) {
            dispatch(showSuccessSnackbar("Please select only one Subscription", "warning"));
        }
        else if (selected.length === 1) {
            nevigate.push('detailsubscription/' + selected.toString());
        }
    }
    const deletepre = (event, selected) => {
        if (selected.length > 1) {
            dispatch(showSuccessSnackbar("Please select only one Subscription", "warning"));
        }
        else {
            //DeletePrivilege(selected.toString(), successdel)
        }
    }
    useEffect(() => {
        GetSubscriptionList(CallbackData);
    }, []);
    return (
        <div>
            <AppBarDashboard>
                <Link to="/addsubscription">
                    <Button
                        varient="contained"
                        className={classes.addbtn}
                        size="large"
                    >
                        Add Subscription
                    </Button>

                </Link>
                <GenericTable
                    rows={allrows}
                    headCells={headCells}
                    gridname="Subscription List"
                    handleDetail={openDetails}
                    onedit={editpre}
                    handledelete={deletepre}
                />
            </AppBarDashboard>
        </div>
    );
};
export default SubscriptionGrid;
