import React from 'react';
import { setSessionStorageItem, getSessionStorageItem } from '../../helpers/utility';



export function getERecipt(cb, id) {
    debugger;
    let URL = window.APP.CONFIGURATION.API + "fetch/sales-invoice/" + id;
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    };
    fetch(URL, requestOptions)
        .then(response => response.json())
        .then(data => {
            debugger;
            console.log(data)
            if (data.status) {
                cb(data);
            }
        });
}