import React from 'react';
import Button from '@material-ui/core/Button';
import { useStyles } from './button.style';

const Btn = ({ type, buttontext, clickhandler }) => {
    const classes = useStyles();
    return (
        <div>
            <Button
                varient="contained"
                color="primary"
                type={type}
                onClick={clickhandler}
                className={classes.loginbutton}
                size="large"
            >
                {buttontext}
            </Button>
        </div>
    );
};
export default Btn;
