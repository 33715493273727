import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { useSelector, useDispatch } from 'react-redux';
import { clearSnackbar } from '../../../stores/actions/snackBarActions';

const SnackMessage = () => {
    const dispatch = useDispatch();
    const { successSnackbarOpen, message, snackType } = useSelector(
        state => state.ui
    );

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        dispatch(clearSnackbar());
    };
    return (
        <div>
            <Snackbar open={successSnackbarOpen}  autoHideDuration={4000} onClose={handleClose}>
                <Alert severity={snackType}>
                    {message}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default SnackMessage;

SnackMessage.defaultProps = {
    isOpen: false,
    message: ''
}
