import { Grid } from '@material-ui/core';
import React, { useEffect } from 'react';
import './eReciptStyle.css';
import { useTranslation } from "react-i18next";
import LanguageSelect from '../../components/atoms/languageSelector/languageSelector';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { getERecipt } from '../../services/general/generalService';
import { showSuccessSnackbar } from '../../stores/actions/snackBarActions';
import logo from '../../assets/images/fresh.png'


const ERecipt = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const dispatch = useDispatch();
    const [OrgName, setOrgName] = React.useState('');
    const [OrgPhone, setOrgPhone] = React.useState('');
    const [VatId, setVatId] = React.useState('');
    const [InvoiceId, setInvoiceId] = React.useState('');
    const [PaymentType, setPaymentType] = React.useState('');
    const [InvoiceTime, setInvoiceTime] = React.useState('');
    const [SubTotal, setSubTotal] = React.useState('');
    const [TotalVAT, setTotalVAT] = React.useState('');
    const [GrandTotal, setGrandTotal] = React.useState('');
    const CallbackData = data => {
        debugger;
        if (data.status) {
            let invoice = data.data[0];
            dispatch(showSuccessSnackbar(data.message, "success"));
            setOrgName(invoice.entity_name);
            setOrgPhone(invoice.entity_phone);
            setVatId(invoice.entity_vat);
            setInvoiceId(invoice.invoice_id);
            setPaymentType(invoice.payment_type);
            setInvoiceTime(invoice.sales_time);
            setSubTotal(invoice.sub_total);
            setTotalVAT(invoice.total_VAT);
            setGrandTotal(invoice.total_amount);
        }
        else {
            dispatch(showSuccessSnackbar(data.message, "error"));
        }

    }
    useEffect(() => {
        document.body.style.backgroundColor = "#e4e5e6";
        getERecipt(CallbackData, id);
    }, []);
    return (
        <>
            <div className="language-select">
                <LanguageSelect />
            </div>
            <div className="containerWrapper">
                <div className="containerrecipt">

                    <div className='logoContainer'>
                        <img src={logo} width="150" height="50" className='logoImg' />
                    </div>
                    <h3 className="OrgName">
                        {OrgName}
                    </h3>
                    <div className="invoiceNo">
                        {OrgPhone}
                    </div>
                    <Grid container>
                        <Grid item xs={7}>
                            {t("vat_Id")}
                        </Grid>
                        <Grid className="text-right" item xs={5}>
                            {VatId}
                        </Grid>
                        <Grid item xs={7}>
                            {t("InvoiceNo")}
                        </Grid>
                        <Grid className="text-right" item xs={5}>
                            {InvoiceId}
                        </Grid>
                        <Grid item xs={7}>
                            {t("InvoiceDate")}
                        </Grid>
                        <Grid className="text-right" item xs={5}>
                            {InvoiceTime}
                        </Grid>
                        <Grid item xs={7}>
                            {t("TaxAbleSubTotal")}
                        </Grid>
                        <Grid className="text-right" item xs={5}>
                            SAR  {SubTotal}
                        </Grid>
                        <Grid item xs={7}>
                            {t("VAT_Total(15%)")}
                        </Grid>
                        <Grid className="text-right" item xs={5}>
                            SAR  {TotalVAT}
                        </Grid>
                        <Grid item xs={7}>
                            {t("GrandTotal")}
                        </Grid>
                        <Grid className="text-right" item xs={5}>
                            SAR {GrandTotal}
                        </Grid>
                    </Grid>
                    <div className="text-center bottomtxt">
                        <div>Powered By</div>
                        <img src={logo} width="100" height="35" />
                    </div>
                </div>
            </div>

        </>
    )
}
export default ERecipt;