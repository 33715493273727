import React from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Button from '@material-ui/core/Button';
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Collapse from '@material-ui/core/Collapse';
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { useStyles } from "./appBar.style";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "../../../stores/reducers/userReducer";
import { useHistory } from "react-router";
import { setSessionStorageItem } from "../../../helpers/utility";


const AppBarDashboard = ({ children }) => {
    const dispatch = useDispatch();
    const nevigate = useHistory();
    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const [openmenu1, setOpenmenu1] = React.useState(true);
    const [openmenu2, setOpenmenu2] = React.useState(true);
    const [openmenu3, setOpenmenu3] = React.useState(true);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };
    const handleClickmenu1 = () => {
        setOpenmenu1(!openmenu1);
    };
    const handleClickmenu2 = () => {
        setOpenmenu2(!openmenu2);
    };
    const handleClickmenu3 = () => {
        setOpenmenu3(!openmenu3);
    };
    const logoutuser = () => {
        dispatch(logout());
        //setSessionStorageItem('token', '');
        nevigate.push("/");
    }
    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open
                })}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        className={clsx(classes.menuButton, open && classes.hide)}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap>
                        Dashboard
                    </Typography>
                    <Button className={classes.logoutbtn} onClick={logoutuser} color="inherit">Logout</Button>
                </Toolbar>
            </AppBar>
            <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="left"
                open={open}
                classes={{
                    paper: classes.drawerPaper
                }}
            >
                <div className={classes.drawerHeader}>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === "ltr" ? (
                            <ChevronLeftIcon />
                        ) : (
                            <ChevronRightIcon />
                        )}
                    </IconButton>
                </div>
                <Divider />
                <List>
                    <ListItem button className={classes.parentlist} onClick={handleClickmenu1}>
                        <ListItemText primary="User & Privilages" />
                        {openmenu1 ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={openmenu1} timeout="auto" unmountOnExit>

                        <List component="div" disablePadding>
                            <Link to="/rolesprivileges">
                                <ListItem button className={classes.nested}>
                                    <ListItemText primary="Roles & Privilages" />
                                </ListItem>
                            </Link>
                            <Link to="/users">
                                <ListItem button className={classes.nested}>
                                    <ListItemText primary="Users" />
                                </ListItem>
                            </Link>

                        </List>
                    </Collapse>
                    <Link to="/subscription">
                        <ListItem button>
                            <ListItemText primary="Subscriptions" />
                        </ListItem>
                    </Link>
                    <ListItem button>
                        <ListItemText primary="Configurations" />
                    </ListItem>
                </List>
                <Divider />
                <List>
                    <ListItem button className={classes.parentlist} onClick={handleClickmenu2}>
                        <ListItemText primary="Packages & Features" />
                        {openmenu2 ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={openmenu2} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <Link to="/packages">
                                <ListItem button className={classes.nested}>
                                    <ListItemText primary="Packages" />
                                </ListItem>
                            </Link>
                            <Link to="/features">
                                <ListItem button className={classes.nested}>
                                    <ListItemText primary="Features" />
                                </ListItem>
                            </Link>

                        </List>
                    </Collapse>
                </List>
                <Divider />
                <List>
                    <ListItem button className={classes.parentlist} onClick={handleClickmenu3}>
                        <ListItemText primary="POS Managment" />
                        {openmenu3 ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={openmenu3} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem button className={classes.nested}>
                                <ListItemText primary="Catalogue Managment" />
                            </ListItem>
                            <ListItem button className={classes.nested}>
                                <ListItemText primary="Vendor Managment" />
                            </ListItem>
                        </List>
                    </Collapse>
                </List>
            </Drawer>
            <main
                className={clsx(classes.topmargn, classes.content, {
                    [classes.contentShift]: open
                })}
            >
                {
                    children
                }
            </main>
        </div>
    );
};
export default AppBarDashboard;
