import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from "react-redux";
import Grid from '@material-ui/core/Grid';
import Input from "../../components/atoms/input/input";
import Btn from "../../components/atoms/button/button";
import { LoginUser } from "../../services/loginService/loginService";
import { useStyles } from './login.style';
import { login } from '../../stores/reducers/userReducer';
import { useHistory } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { showSplashScreen, hideSplashScreen } from "../../helpers/splashScreen";
import { showSuccessSnackbar } from '../../stores/actions/snackBarActions';
import ReactPinField from 'react-pin-field';
import MuiPhoneNumber from 'material-ui-phone-number';
import './login.css';

const Login = () => {
    const dispatch = useDispatch();
    const nevigate = useHistory();
    const [phonevalue, setphonevalue] = useState("");
    const [passwordvalue, setpasswordvalue] = useState("");
    const [phoneerror, setphoneerror] = useState("");
    const [phonevalidation, setphonevalidation] = useState(false);
    const [passworderror, setpassworderror] = useState("");
    const [passwordvalidation, setpasswordvalidation] = useState(false);
    const classes = useStyles();
    const handleclick = () => {

    }
    const loginsuccesscb = data => {
        debugger;
        if (!data.status) {
            dispatch(showSuccessSnackbar(data.message, "error"));
        }
        else {
            dispatch(login(data.data[0].user));
            nevigate.push("/dashboard");
            hideSplashScreen();
        }

    }
    const phoneRegExp = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
    const formik = useFormik({
        initialValues: {
            phone: '',
            password: '',
        },
        validationSchema: Yup.object({
            phone: Yup.string().required('Required'),
            password: Yup.string().min(4, "Must be more than 4 characters").required('Required'),
        }),
        onSubmit: values => {
            debugger;
            showSplashScreen();
            LoginUser(values.phone, values.password, loginsuccesscb);
            //alert(JSON.stringify(values, null, 2));
        },
    });
    const changepassword = (e) => {
        debugger;
        if (e == "") {
            setpassworderror(formik.errors.password);
            setpasswordvalidation(true);
        }
        else {
            setpassworderror("");
            setpasswordvalidation(false);
            setpasswordvalue(e);
        }
    }
    const handlechange = (e) => {
        debugger;
        if (e == '') {
            setphoneerror(formik.errors.phone);
            setphonevalidation(true);
        }
        else {
            setphoneerror("");
            setphonevalidation(false);
            setphonevalue(e.replace("+",""));
        }
        
    }
    const handlesubmitform = (event) => {
        debugger;
        event.preventDefault();
        LoginUser(phonevalue, passwordvalue, loginsuccesscb);
    }
    return (
        <div className={classes.loginwrapper}>
            <h2>Sign In</h2>
            <form onSubmit={handlesubmitform}>
                <Grid container spacing={5}>
                    <Grid item xs={12}>
                        <MuiPhoneNumber
                            {...formik.getFieldProps('phone')}
                            defaultCountry={'sa'}
                            variant="outlined"
                            label="Phone"
                            id="phone"
                            helperText={phoneerror}
                            error={phonevalidation}
                            onChange={handlechange}
                        />,
                        {/* <Input
                            {...formik.getFieldProps('phone')}

                            type="text"
                            labeltext="Phone"
                            changehandler={handlechange}

                        /> */}
                    </Grid>
                    <Grid item className={classes.pinfield} xs={12}>
                        <ReactPinField
                            {...formik.getFieldProps('password')}
                            id="password"
                            type="password"
                            className="pin-field"
                            onChange={changepassword}
                        />
                        {/* <Input
                            {...formik.getFieldProps('password')}
                            id="password"
                            type="password"
                            labeltext="Password"
                            changehandler={handlechange}
                            helperText={passworderror}
                            error={passwordvalidation}
                        /> */}
                    </Grid>
                </Grid>
                <Btn
                    type="submit"
                    buttontext="Login"
                    clickhandler={handleclick}
                />
            </form>
        </div>
    );
};
export default withRouter(Login);
