import { createSlice } from "@reduxjs/toolkit";
import { number } from "yup";

export const UserReducer = createSlice({
    name: "user",
    initialState: {
        user: null
    },
    reducers: {
        login: (state, action) => {
            state.user = action.payload;
        },
        logout: (state) =>{
            state.user = null;
        }
    }
});

export const { login, logout } = UserReducer.actions;

export const selectUser = (state) => state.user.user;

export default UserReducer.reducer;