import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { useParams } from 'react-router-dom';
import { useStyles } from './subscription.style';
import { getSubscriptionDetail } from '../../../services/subscriptionService/subscriptionService';
import AppBarDashboard from '../../molecules/appBar/appBar';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { showSuccessSnackbar } from '../../../stores/actions/snackBarActions';
import GenericTable from '../../atoms/table/table';
import QRCode from "qrcode.react";


const SubscriptionDetails = () => {
    const classes = useStyles();
    const { id } = useParams();
    const dispatch = useDispatch();
    const nevigate = useHistory();
    const [branchesTable, setBranchesTable] = React.useState([]);
    const [packagesrows, setpackagesrows] = React.useState([]);
    const [entityEntityId, setentityEntityId] = React.useState('');
    const [entityname, setentityname] = React.useState('');
    const [entityvat_id, setentityvat_id] = React.useState('');
    const [entitycr_number, setentitycr_number] = React.useState('');
    const [subscriptionsubscription_id, setsubscriptionsubscription_id] = React.useState('');
    const [subscriptionpayment_method, setsubscriptionpayment_method] = React.useState('');
    const [subscriptionpayment_status, setsubscriptionpayment_status] = React.useState('');
    const [subscriptionreference_no, setsubscriptionreference_no] = React.useState('');
    const [subscriptiondate, setsubscriptiondate] = React.useState('');
    const [subscriptionstart_date, setsubscriptionstart_date] = React.useState('');
    const [subscriptionend_date, setsubscriptionend_date] = React.useState('');
    const [subscriptiontotal, setsubscriptiontotal] = React.useState('');
    const [subscriptionsubscription_key, setsubscriptionsubscription_key] = React.useState('');
    const [agentagent_name, setagentagent_name] = React.useState('');
    const [agentagent_phone, setagentagent_phone] = React.useState('');

    const headCells = [
        { id: 'branch_id', numeric: false, disablePadding: true, label: 'Branch Id' },
        { id: 'description', numeric: false, disablePadding: true, label: 'Description' },
        { id: 'city', numeric: false, disablePadding: true, label: 'City' },
        { id: 'district', numeric: false, disablePadding: true, label: 'District' },
        { id: 'store_type', numeric: false, disablePadding: true, label: 'Store Type' },
        { id: 'product_type', numeric: false, disablePadding: true, label: 'Product Type' },
        { id: 'catalog_type', numeric: false, disablePadding: true, label: 'Catalog Type' },
        { id: 'source_type', numeric: false, disablePadding: true, label: 'Source Type' },
        { id: 'manager_name', numeric: false, disablePadding: true, label: 'Manager Name' },
        { id: 'manager_phone', numeric: false, disablePadding: true, label: 'Manager Phone' },
        { id: 'operator_name', numeric: false, disablePadding: true, label: 'Operator Name' },
        { id: 'operator_phone', numeric: false, disablePadding: true, label: 'Operator Phone' },
    ];
    const editpre = (event, selected) => {
        debugger;
        if (selected.length > 1) {
            dispatch(showSuccessSnackbar("Please select only one Subscription", "warning"));
        }
        else if (selected.length === 1) {
            //nevigate.push('addrolesprivileges?id=' + selected.toString());
        }
    }
    const openDetails = (event, selected) => {
        debugger;
        if (selected.length > 1) {
            dispatch(showSuccessSnackbar("Please select only one Subscription", "warning"));
        }
        else if (selected.length === 1) {
            nevigate.push('detailsubscription/' + selected.toString());
        }
    }
    const deletepre = (event, selected) => {
        if (selected.length > 1) {
            dispatch(showSuccessSnackbar("Please select only one Subscription", "warning"));
        }
        else {
            //DeletePrivilege(selected.toString(), successdel)
        }
    }
    const successDetail = (data) => {
        debugger;
        if (data.status) {
            setBranchesTable(data.data[0]?.branches);
            setentityEntityId(data.data[0]?.entity?.entity_id);
            setentityname(data.data[0]?.entity?.name);
            setentityvat_id(data.data[0]?.entity?.vat_id);
            setentitycr_number(data.data[0]?.entity?.cr_number);
            setsubscriptionsubscription_id(data.data[0]?.subscription?.subscription_id);
            setsubscriptionpayment_method(data.data[0]?.subscription?.payment_method);
            setsubscriptionpayment_status(data.data[0]?.subscription?.payment_status);
            setsubscriptionreference_no(data.data[0]?.subscription?.reference_no);
            setsubscriptiondate(data.data[0]?.subscription?.date);
            setsubscriptionstart_date(data.data[0]?.subscription?.start_date);
            setsubscriptionend_date(data.data[0]?.subscription?.end_date);
            setsubscriptiontotal(data.data[0]?.subscription?.total);
            setsubscriptionsubscription_key(data.data[0]?.subscription?.subscription_key);
            setagentagent_name(data.data[0]?.agent?.agent_name);
            setagentagent_phone(data.data[0]?.agent?.agent_phone);
            setpackagesrows(data.data[0]?.packages);
        }
    }
    useEffect(() => {
        getSubscriptionDetail(successDetail, id)
    }, []);
    return (
        <AppBarDashboard>
            <div>
                <Grid container>
                    <Grid item xs={9}>
                        <Card className={classes.packgCard} sx={{ minWidth: 275 }}>
                            <CardContent>
                                <Typography variant="h5" component="div">
                                    Packages Details
                                </Typography>
                                {packagesrows.map(x => {
                                    debugger;
                                    return (
                                        <Typography className={classes.cardtable} sx={{ mb: 1.5 }} color="text.secondary">
                                            <Grid container>
                                                <Grid item xs={2}>
                                                    PackageId:
                                                </Grid>
                                                <Grid item xs={2}>
                                                    {x?.package_id}
                                                </Grid>
                                                <Grid item xs={2}>
                                                    Payment Type:
                                                </Grid>
                                                <Grid item xs={2}>
                                                    {x?.payment_type}
                                                </Grid>
                                                <Grid item xs={2}>
                                                    Price:
                                                </Grid>
                                                <Grid item xs={2}>
                                                    {x?.price}
                                                </Grid>
                                            </Grid>
                                        </Typography>
                                    )

                                })}

                            </CardContent>

                        </Card>
                    </Grid>
                    <Grid item xs={3}>
                        <div className={classes.ActionContainer}>
                            <Button
                                variant="outlined"
                                color="primary"
                                size="large"
                                onClick={() => window.print()}
                            >
                                Print
                            </Button>
                            <div className={classes.spacingletter}>
                                <b>Payment Status</b>: {subscriptionpayment_status}
                            </div>
                            <div className={classes.spacingletter}>
                                <b>Agent</b>: {agentagent_name}
                            </div>

                        </div>
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid item xs={9}>
                        <Card className={classes.EntityCard} sx={{ minWidth: 275 }}>
                            <CardContent>
                                <Typography variant="h5" component="div">
                                    <Grid container>
                                        <Grid item xs={6}>
                                            Entity Details
                                        </Grid>
                                        <Grid item xs={6}>

                                        </Grid>
                                    </Grid>
                                </Typography>
                                <Typography className={classes.cardtable} sx={{ mb: 1.5 }} color="text.secondary">
                                    <Grid container>
                                        <Grid item xs={6}>
                                            EntityId:
                                        </Grid>
                                        <Grid item xs={6}>
                                            {entityEntityId}
                                        </Grid>
                                    </Grid>
                                </Typography>
                                <Typography className={classes.cardtable} sx={{ mb: 1.5 }} color="text.secondary">
                                    <Grid container>
                                        <Grid item xs={6}>
                                            Name:
                                        </Grid>
                                        <Grid item xs={6}>
                                            {entityname}
                                        </Grid>
                                    </Grid>
                                </Typography>
                                <Typography className={classes.cardtable} sx={{ mb: 1.5 }} color="text.secondary">
                                    <Grid container>
                                        <Grid item xs={6}>
                                            VAT Id:
                                        </Grid>
                                        <Grid item xs={6}>
                                            {entityvat_id}
                                        </Grid>
                                    </Grid>
                                </Typography>
                                <Typography className={classes.cardtable} sx={{ mb: 1.5 }} color="text.secondary">
                                    <Grid container>
                                        <Grid item xs={6}>
                                            Customer Number:
                                        </Grid>
                                        <Grid item xs={6}>
                                            {entitycr_number}
                                        </Grid>
                                    </Grid>
                                </Typography>
                            </CardContent>

                        </Card>
                    </Grid>
                </Grid>

                <div className={classes.beanchtable}>
                    <GenericTable
                        rows={branchesTable}
                        headCells={headCells}
                        gridname="Branches List"
                        handleDetail={openDetails}
                        onedit={editpre}
                        handledelete={deletepre}

                    />
                </div>
                <Grid container>
                    <Grid item xs={9}>
                        <Card className={classes.Subscriptcard} sx={{ minWidth: 275 }}>
                            <CardContent>
                                <Typography variant="h5" component="div">
                                    <Grid container>
                                        <Grid item xs={6}>
                                            Subscription
                                        </Grid>
                                        <Grid item xs={6}>

                                        </Grid>
                                    </Grid>
                                </Typography>
                                <Typography className={classes.cardtable} sx={{ mb: 1.5 }} color="text.secondary">
                                    <Grid container>
                                        <Grid item xs={6}>
                                            Subscription Id:
                                        </Grid>
                                        <Grid item xs={6}>
                                            {subscriptionsubscription_id}
                                        </Grid>
                                    </Grid>
                                </Typography>
                                <Typography className={classes.cardtable} sx={{ mb: 1.5 }} color="text.secondary">
                                    <Grid container>
                                        <Grid item xs={6}>
                                            Payment Method:
                                        </Grid>
                                        <Grid item xs={6}>
                                            {subscriptionpayment_method}
                                        </Grid>
                                    </Grid>
                                </Typography>
                                <Typography className={classes.cardtable} sx={{ mb: 1.5 }} color="text.secondary">
                                    <Grid container>
                                        <Grid item xs={6}>
                                            Payment Status:
                                        </Grid>
                                        <Grid item xs={6}>
                                            {subscriptionpayment_status}
                                        </Grid>
                                    </Grid>
                                </Typography>
                                <Typography className={classes.cardtable} sx={{ mb: 1.5 }} color="text.secondary">
                                    <Grid container>
                                        <Grid item xs={6}>
                                            Reference No.:
                                        </Grid>
                                        <Grid item xs={6}>
                                            {subscriptionreference_no}
                                        </Grid>
                                    </Grid>
                                </Typography>
                                <Typography className={classes.cardtable} sx={{ mb: 1.5 }} color="text.secondary">
                                    <Grid container>
                                        <Grid item xs={6}>
                                            Date:
                                        </Grid>
                                        <Grid item xs={6}>
                                            {subscriptiondate}
                                        </Grid>
                                    </Grid>
                                </Typography>
                                <Typography className={classes.cardtable} sx={{ mb: 1.5 }} color="text.secondary">
                                    <Grid container>
                                        <Grid item xs={6}>
                                            Start Date:
                                        </Grid>
                                        <Grid item xs={6}>
                                            {subscriptionstart_date}
                                        </Grid>
                                    </Grid>
                                </Typography>
                                <Typography className={classes.cardtable} sx={{ mb: 1.5 }} color="text.secondary">
                                    <Grid container>
                                        <Grid item xs={6}>
                                            End Date:
                                        </Grid>
                                        <Grid item xs={6}>
                                            {subscriptionend_date}
                                        </Grid>
                                    </Grid>
                                </Typography>
                                <Typography className={classes.cardtable} sx={{ mb: 1.5 }} color="text.secondary">
                                    <Grid container>
                                        <Grid item xs={6}>
                                            Total:
                                        </Grid>
                                        <Grid item xs={6}>
                                            {subscriptiontotal}
                                        </Grid>
                                    </Grid>
                                </Typography>
                                {/* <Typography className={classes.cardtable} sx={{ mb: 1.5 }} color="text.secondary">
                                    <Grid container>
                                        <Grid item xs={6}>
                                            Subscription Key:
                                        </Grid>
                                        <Grid item xs={6}>
                                        </Grid>
                                    </Grid>
                                </Typography> */}
                            </CardContent>

                        </Card>
                    </Grid>
                    <Grid item xs={3}>
                        <div className={classes.qrcode}>
                            <h2>Subscription Key:</h2>

                            <QRCode
                                id="qr-gen"
                                value={subscriptionsubscription_key}
                                size={190}
                                level={"H"}
                                includeMargin={true}
                            />
                        </div>
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid item xs={9}>
                        <Card className={classes.EntityCard} sx={{ minWidth: 275 }}>
                            <CardContent>
                                <Typography variant="h5" component="div">
                                    <Grid container>
                                        <Grid item xs={6}>
                                            Agent Details
                                        </Grid>
                                        <Grid item xs={6}>

                                        </Grid>
                                    </Grid>
                                </Typography>
                                <Typography className={classes.cardtable} sx={{ mb: 1.5 }} color="text.secondary">
                                    <Grid container>
                                        <Grid item xs={6}>
                                            Agent Name:
                                        </Grid>
                                        <Grid item xs={6}>
                                            {agentagent_name}
                                        </Grid>
                                    </Grid>
                                </Typography>
                                <Typography className={classes.cardtable} sx={{ mb: 1.5 }} color="text.secondary">
                                    <Grid container>
                                        <Grid item xs={6}>
                                            Agent Phone:
                                        </Grid>
                                        <Grid item xs={6}>
                                            {agentagent_phone}
                                        </Grid>
                                    </Grid>
                                </Typography>

                            </CardContent>

                        </Card>
                    </Grid>
                </Grid>

            </div>
        </AppBarDashboard >

    );
};
export default SubscriptionDetails;
