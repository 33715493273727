import { Button } from '@material-ui/core';
import { CallMissedSharp } from '@material-ui/icons';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getAllRolesPrivileges, getAllModules, getAllUserType, getAllAccountType, DeletePrivilege } from '../../../services/userService/userService';
import GenericTable from '../../atoms/table/table';
import AddPrivileges from './addPrivileges';
import { useStyles } from './rolePrivileges.style';
import AppBarDashboard from '../../molecules/appBar/appBar';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { showSuccessSnackbar } from '../../../stores/actions/snackBarActions';

const RolePrivilegesGrid = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const nevigate = useHistory();
    const [allrows, setallrows] = React.useState([]);
    const CallbackData = data => {
        setallrows(data);
    }
    const headCells = [
        { id: 'id', numeric: false, disablePadding: true, label: 'Id' },
        { id: 'description', numeric: false, disablePadding: true, label: 'Description' },
        { id: 'type', numeric: false, disablePadding: true, label: 'Type' },
        { id: 'status', numeric: false, disablePadding: true, label: 'Status' },
    ];
    useEffect(() => {
        getAllRolesPrivileges(CallbackData);
    }, []);
    const editpre = (event, selected) => {
        debugger;
        if (selected.length > 1) {
            dispatch(showSuccessSnackbar("Please select only one Prevelige", "warning"));
        }
        else if (selected.length === 1) {
            nevigate.push('addrolesprivileges?id=' + selected.toString());
        }
    }
    const successdel = data => {
        if (data.status) {
            dispatch(showSuccessSnackbar(data.message, "success"));
        }
        else {
            dispatch(showSuccessSnackbar(data.message, "error"));
        }
    }
    const deletepre = (event, selected) => {
        if (selected.length > 1) {
            dispatch(showSuccessSnackbar("Please select only one Prevelige", "warning"));
        }
        else {
            DeletePrivilege(selected.toString(), successdel)
        }
    }
    return (
        <AppBarDashboard>
            <div>
                <Link to="/addrolesprivileges">
                    <Button
                        varient="contained"
                        className={classes.addbtn}
                        size="large"
                    >
                        Add Privileges
                    </Button>
                </Link>

                <GenericTable
                    rows={allrows}
                    headCells={headCells}
                    gridname="Privileges"
                    onedit={editpre}
                    handledelete={deletepre}
                />
            </div>
        </AppBarDashboard>

    );
}
export default RolePrivilegesGrid;