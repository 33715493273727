import React from 'react';
import { useStyles } from './input.styles';
import TextField from '@material-ui/core/TextField';


const Input = ({
    type,
    labeltext,
    changehandler,
    id,
    helperText,
    error,
    ...formfield
}) => {
    const classes = useStyles();
    return (
        <div className={classes.inputwrapper}>
            <TextField
                id={id}
                label={labeltext}
                type={type}
                className={classes.input}
                onKeyUp={changehandler}
                variant="outlined"
                helperText={helperText}
                error={error}
                onChange={changehandler}
                {...formfield}
            />
        </div>
    );
};
export default Input;
