import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from './stores/store';
import './index.css';
import App from './wrappers/AppRouter';
import reportWebVitals from './reportWebVitals';
import { saveToLocalStorage,loadFromLocalStorage } from './helpers/presistState';
import { createStore } from 'redux';
import './i18nextInit';


ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);
window.addEventListener('beforeunload', function (e) {
  console.log(store.getState());
  saveToLocalStorage(store.getState());
  console.log(loadFromLocalStorage());
});
window.addEventListener('load',function (params) {
  //var statee = loadFromLocalStorage();
});
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
