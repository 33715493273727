import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Grid from '@material-ui/core/Grid';
import Input from '../../atoms/input/input';
import Btn from '../../atoms/button/button';
import DropDown from '../../atoms/dropdown/dropdown';
import { hideSplashScreen, showSplashScreen } from '../../../helpers/splashScreen';
import AppBarDashboard from '../../molecules/appBar/appBar';
import { getAllFeatures, getAllStatus, getAllPaymentTypes } from '../../../services/featurePackageService/featurePackageService';
import { showSuccessSnackbar } from '../../../stores/actions/snackBarActions';
import { createPackage } from '../../../services/featurePackageService/featurePackageService';

const AddPackage = () => {
    const nevigate = useHistory();
    const dispatch = useDispatch();
    const [descriptionerror, setdescriptionerror] = React.useState("");
    const [descriptionValid, setdescriptionValid] = React.useState(false);
    const [promocodeerror, setpromocodeerror] = React.useState("");
    const [promocodeValid, setpromocodevalid] = React.useState(false);
    const [paymentType, setpaymentType] = React.useState('');
    const [features, setfeatures] = React.useState([]);
    const [status, setstatus] = React.useState('');
    const [featuredropdown, setfeaturedropdown] = React.useState([]);
    const [paymentdropdown, setpaymentdropdown] = React.useState([]);
    const [statusdropdown, setstatusdropdown] = React.useState([]);

    const formik = useFormik({
        initialValues: {
            description: '',
            paymentType: '',
            features: [],
            statuspackage: '',
            promocode: '',
        },
        validationSchema: Yup.object({
            description: Yup.string().required('Required'),

            //            email: Yup.string().email('Invalid email address').required('Required'),
        }),
        onSubmit: values => {
            debugger;
            //alert(JSON.stringify(values, null, 2));
            createPackage(values, successpackageAdded)
        },
    });
    const handlechange = () => {

    }
    const handlechangepaymentType = () => {
        debugger;
    }
    const successpackageAdded = data => {
        if (data.status) {
            dispatch(showSuccessSnackbar(data.message, "success"));
            nevigate.push("/packages");
            hideSplashScreen();
        }
        else{
            dispatch(showSuccessSnackbar(data.message, "error"));
            hideSplashScreen();
        }
       
    }
    const handlefeature = (event) => {
        debugger;
        setfeatures(event.target.value);
    }
    const successstatus = data => {
        setstatusdropdown(data);
    }
    const handlestatus = (event) => {
        debugger;
        setstatus(event.target.value);
    }
    const handleclick = () => {

    }
    const getfeatureData = (data) => {
        debugger;
        setfeaturedropdown(data);

    }
    const getpaymentData = (data) => {
        setpaymentdropdown(data)
    }
    useEffect(() => {
        getAllFeatures(getfeatureData);
        getAllPaymentTypes(getpaymentData);
        getAllStatus(successstatus);
    }, []);
    return (
        <AppBarDashboard>
            <div>
                <h3>Add Package</h3>
                <form onSubmit={formik.handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Input
                                {...formik.getFieldProps('description')}
                                id="description"
                                type="text"
                                labeltext="Description"
                                changehandler={handlechange}
                                helperText={descriptionerror}
                                error={descriptionValid}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <DropDown
                                valuedropdown={paymentType}
                                setvaluedropdown={setpaymentType}
                                changehandler={handlechangepaymentType}
                                id="paymentType"
                                labeltext="Payment Type"
                                dropdownitems={paymentdropdown}
                                {...formik.getFieldProps('paymentType')}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <DropDown
                                valuedropdown={features}
                                setvaluedropdown={setfeatures}
                                changehandler={handlefeature}
                                id="features"
                                labeltext="Features"
                                dropdownitems={featuredropdown}
                                multiple
                                {...formik.getFieldProps('features')}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <DropDown
                                valuedropdown={status}
                                setvaluedropdown={setstatus}
                                changehandler={handlestatus}
                                id="statuspackage"
                                labeltext="Status"
                                dropdownitems={statusdropdown}
                                {...formik.getFieldProps('statuspackage')}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Input
                                {...formik.getFieldProps('promocode')}
                                id="promocode"
                                type="text"
                                labeltext="Promo Code"
                                changehandler={handlechange}
                                helperText={promocodeerror}
                                error={promocodeValid}
                            />
                        </Grid>
                    </Grid>
                    <Btn
                        type="submit"
                        buttontext="Save"
                        clickhandler={handleclick}
                    />
                </form>
            </div>
        </AppBarDashboard>

    );
}
export default AddPackage;