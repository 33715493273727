import React from 'react';
import { hideSplashScreen } from '../../helpers/splashScreen';
import {setSessionStorageItem, getSessionStorageItem} from '../../helpers/utility';
export function GetSubscriptionList(cb) {
    debugger;
    let token = getSessionStorageItem('token');
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
    };
    let URL = window.APP.CONFIGURATION.API + 'fetch/subscriptions';
    fetch(URL, requestOptions)
        .then(response => response.json())
        .then(data => {
            debugger;
            if (data.status) {
                cb(data);
                
            }
        });

}



export function getSubscriptionDetail(cb, id) {
    debugger;

    let URL = window.APP.CONFIGURATION.API + "fetch/subscription/" + id;
    let token = getSessionStorageItem('token');
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
    };
    fetch(URL, requestOptions)
        .then(response => response.json())
        .then(data => {
            debugger;
            console.log(data)
            if (data.status) {
                cb(data);
            }
        });
}