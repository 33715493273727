import { createTheme } from '@material-ui/core/styles';

let navy = '#0C5192';
let navylight = '#306AA2';
let navylighter = '#85A8C8';
let purple = '#543D86';
let purpleLight = '#6E5A98';
let purpleLighter = '#A99EC2';
let ultraviolet = '#2A0481';
let blueDark = '#244859';
let blue = '#0190B0';
let brightblue = '#238196';
let greenLighter = '#E5F1EF';
let greenLight = '#228774';
let green = '#007760';
let greenMedium = '#81A485';
let greenDark = '#044A0B';
let red = '#C32549';
let redLight = '#CF516D';
let redlighter = '#F0E4E8';
let redDark = '#7f1f35';
let orange = '#F4A14E';
let orangeLight = '#F6B371';
let orangeLighter = '#FDECDC';
let yellow = '#FFE037';

// Monochrome
let lightest = '#FFFFFF';
let lighter = '#F4F6F7'; // veryLightGrey
let light = '#E1E6EA'; // lightGrey
let mediumLight = '#C3CED6'; // paleGrey
let medium = '#4C5765'; // midGrey
let dark = '#3C444E'; // darkGrey
let darker = '#2F3740'; // veryDarkGrey
let darkLight = 'rgba(0, 0, 0, 0.25)';
let darkest = '#000000';
let lightpurple = '#584187';
let lightgrayU = '#eeecf3';

// Border
let borderWidth = '1px';
let borderMinRadius = '1px';
let borderRadius = '4px';
let borderMaxRadius = '8px';
let buttonHeight = '48px';

// Global style variables
export const baseColor = {
  navy,
  navylight,
  navylighter,
  purple,
  purpleLight,
  purpleLighter,
  ultraviolet,
  blue,
  brightblue,
  greenLighter,
  greenLight,
  green,
  greenMedium,
  greenDark,
  red,
  redLight,
  redlighter,
  redDark,
  orange,
  orangeLight,
  orangeLighter,
  yellow,

  // Monochrome
  lightest,
  lighter,
  light,
  mediumLight,
  medium,
  dark,
  darker,
  darkLight,
  darkest,
  lightpurple,
  lightgrayU,

  // Status
  success: greenDark,
  error: red,
  errorLight: redLight,
  warning: orange,
  warningLight: orangeLight,
  notification: yellow,

  // icon Colors
  iconSuccess: green,
  iconError: red,

  // Supporting Interface Colors
  patient: blueDark,
  nonSpecificYes: green,
  lightNonSpecificYes: greenLight,

  // Border
  borderWidth,
  borderMinRadius,
  borderRadius,
  borderMaxRadius,

  //buttonHeight
  buttonHeight,
};

export const typography = {
  weight: {
    regular: '400',
    lightBold: '600',
    bold: '700',
  },
  lineHeight: {
    s: '20px',
    m1: '18px',
    m: '24px',
    l: '32px',
    xl: '40px',
    xxl: '48px',
  },
  size: {
    s1: '12px', // disclaimer
    s2: '16px', // body
    m1: '18px', // large
    l1: '20px', // subheading
    l2: '24px',
    l3: '28px',
    l4: '30px',
    l5: '36px',
    l6: '40px',
    l7: '44px',
    l8: '48px',
  },
  radius: {
    r0: '4px',
    r1: '8px',
    r2: '10px',
    r3: '16px',
    r4: '32px',
  },
  fontStyle: {
    main: 'normal',
  },
};

export const theme = createTheme({
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          margin: '0px',
          background: baseColor.lighter,
        },
      },
    },
    MuiPickersCalendarHeader: {
      iconButton: {
        '& svg': {
          background: baseColor.lightest,
        },
      },
      dayLabel: {
        color: baseColor.medium,
      },
    },
  },
  palette: {
    navy,
    navylight,
    purple,
    purpleLight,
    ultraviolet,
    blue,
    brightblue,
    green,
    greenMedium,
    greenDark,
    red,
    primary: {
      main: baseColor.navy,
      contrastText: baseColor.lightest,
    },
    secondary: {
      main: baseColor.purple,
      contrastText: baseColor.lightest,
    },
    waitingAreas: {
      light: baseColor.ultraviolet,
      main: baseColor.purple,
      contrastText: baseColor.lightest,
    },
    users: {
      light: baseColor.brightblue,
      main: baseColor.blue,
      contrastText: baseColor.lightest,
    },
    meetingRooms: {
      light: baseColor.navylight,
      main: baseColor.navy,
      contrastText: baseColor.lightest,
    },
    reports: {
      light: baseColor.medium,
      main: baseColor.dark,
      contrastText: baseColor.lightest,
    },
    status: {
      success: baseColor.success,
      error: {
        light: baseColor.errorLight,
        main: baseColor.error,
      },
      warning: {
        light: baseColor.warningLight,
        main: baseColor.warning,
      },
      notification: baseColor.notification,
      patient: baseColor.patient,
      nonSpecificYes: baseColor.nonSpecificYes,
      lightNonSpecificYes: baseColor.lightNonSpecificYes,
    },
  },
  width: {
    values: {
      xs: 240,
      sm: 400,
      md: 600,
      lg: 960,
      xl: 1024,
    },
  },
  // breakpoints
  breakpoints: {
    values: {
      xs: 460,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  // Typography
  typography: {
    fontFamily: ['"Open Sans"', 'sans-serif'].join(','),
    fontSize: 16,
    fontWeightBold: typography.weight.bold,
    fontWeightMedium: typography.weight.lightBold,
    fontWeightRegular: typography.weight.regular,
    // Headline (content level headings)
    h1: {
      fontSize: typography.size.l3,
      fontWeight: typography.weight.lightBold,
      fontStyle: typography.fontStyle.main,
      lineHeight: typography.lineHeight.xl,
      color: baseColor.darker,
    },
    // H2 Heading
    h2: {
      fontSize: typography.size.l2,
      fontWeight: typography.weight.bold,
      fontStyle: typography.fontStyle.main,
      lineHeight: typography.lineHeight.l,
      color: baseColor.darker,
    },
    // H3 Subheading
    h3: {
      fontSize: typography.size.l1,
      fontWeight: typography.weight.bold,
      fontStyle: typography.fontStyle.main,
      lineHeight: typography.lineHeight.m,
      color: baseColor.darker,
    },
    // Large Text
    h4: {
      fontSize: typography.size.m1,
      fontWeight: typography.weight.regular,
      fontStyle: typography.fontStyle.main,
      lineHeight: typography.lineHeight.m,
      color: baseColor.darker,
    },
    body1: {
      fontSize: typography.size.s2,
      fontWeight: typography.weight.regular,
      fontStyle: typography.fontStyle.main,
      lineHeight: typography.lineHeight.s,
      color: baseColor.darker,
    },
    body2: {
      fontSize: typography.size.m1,
      fontWeight: typography.weight.bold,
      fontStyle: typography.fontStyle.main,
      lineHeight: typography.lineHeight.xl,
      color: baseColor.darker,
    },
    // Disclaimers
    disclaimer: {
      fontSize: typography.size.s1,
      fontWeight: typography.weight.regular,
      fontStyle: typography.fontStyle.main,
      lineHeight: typography.lineHeight.s,
      color: baseColor.darker,
    },
  },
  props: {
    MuiTypography: {
      variantMapping: {
        h4: 'p',
        body1: 'p',
      },
    },
  },
});
