import React from 'react';
import { useStyles } from './dashboard.style';
import AppBarDashboard from '../../components/molecules/appBar/appBar';
import AddUser from '../../components/organisms/User/addUser';
import AddPrivileges from '../../components/organisms/rolesPrivileges/addPrivileges'
import { withRouter } from 'react-router-dom';
import UserGrid from '../../components/organisms/User/userGrid';
import RolePrivilegesGrid from '../../components/organisms/rolesPrivileges/rolePrivilegesGrid';
import FeatureGrid from '../../components/organisms/features/featureGrid';



const DashboardPOS = () => {
    const classes = useStyles();
    return (
        <div>
            <AppBarDashboard>
                <h1>Dashboard</h1>
            </AppBarDashboard>

        </div>
    );
}
export default withRouter(DashboardPOS);