import React from 'react';
import ReactDom from "react-dom"
import Grid from '@material-ui/core/Grid';
import Input from '../../components/atoms/input/input';
import Btn from '../../components/atoms/button/button';
import { showSplashScreen } from '../../helpers/splashScreen';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useStyles } from './login.style';
import { ForgotPassword } from '../../services/loginService/loginService';
import SnackMessage from '../../components/atoms/snackMessage/snackMessage';
import { useDispatch } from 'react-redux';
import { showSuccessSnackbar } from '../../stores/actions/snackBarActions';

const ResetPassword = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [emailerror, setemailerror] = React.useState("");
    const [emailvalidation, setemailvalidation] = React.useState(false);
    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: Yup.object({
            email: Yup.string().email('Invalid email address').required('Required'),
        }),
        onSubmit: values => {
            //showSplashScreen();
            debugger;
            ForgotPassword(values.email, forgotsuccess);
        },
    });
    const forgotsuccess = (data) => {
        debugger;
        if (data.status) {
            dispatch(showSuccessSnackbar("Email has been sent", "success"));
        }
        else {
            dispatch(showSuccessSnackbar(data.message, "error"));

        }

    }
    const handlechange = () => {
        if (formik.touched.email && formik.errors.email) {
            setemailerror(formik.errors.email);
            setemailvalidation(true);
        }
        else {
            setemailerror("");
            setemailvalidation(false);
        }
    }
    const handleclick = () => {

    }
    return (
        <div className={classes.loginwrapper}>
            <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={5}>
                    <Grid item xs={12}>
                        <Input
                            {...formik.getFieldProps('email')}
                            id="email"
                            type="text"
                            labeltext="Email"
                            changehandler={handlechange}
                            helperText={emailerror}
                            error={emailvalidation}
                        />
                    </Grid>
                </Grid>
                <Btn
                    type="submit"
                    buttontext="Reset"
                    clickhandler={handleclick}
                />
            </form>
        </div>
    );
};
export default ResetPassword;
