import React, { useEffect } from 'react';
import AppBarDashboard from '../../molecules/appBar/appBar';
import GenericTable from '../../atoms/table/table';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useStyles } from './feature.style';
import { getAllFeatures } from '../../../services/featurePackageService/featurePackageService';

const FeatureGrid = () => {
    const classes = useStyles();
    const [allrows, setallrows] = React.useState([]);
    const headCells = [
        { id: 'id', numeric: false, disablePadding: true, label: 'Id' },
        { id: 'name', numeric: false, disablePadding: true, label: 'Name' },
        { id: 'price', numeric: false, disablePadding: true, label: 'Price' },
    ];
    const CallbackData = data => {
        setallrows(data);
    }
    useEffect(() => {
        getAllFeatures(CallbackData);
    }, []);
    return (
        <AppBarDashboard>
            <div>
                <Link to="/addfeature">
                    <Button
                        varient="contained"
                        className={classes.addbtn}
                        size="large"
                    >
                        Add Feature
                    </Button>
                </Link>

                <GenericTable rows={allrows} headCells={headCells} gridname="Features" />
            </div>
        </AppBarDashboard>
    );
};
export default FeatureGrid;
