import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
    loginbutton: {
        marginTop: '30px',
        backgroundColor: '#5243AA',
        color: '#fff',
        float: 'right',
        minWidth: '200px',
        '&:hover': {
            marginTop: '30px',
            backgroundColor: '#5243AA',
            color: '#fff',
            float: 'right',
        }
    }
});
