import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
    addbtn: {
        marginBottom: '30px',
        backgroundColor: '#5243AA',
        color: '#fff',
        marginRight: '5px',
        textDecoration: 'none',
        '&:hover': {
            marginBottom: '30px',
            backgroundColor: '#5243AA',
            color: '#fff',
        }
    }
});
