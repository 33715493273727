import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useStyles } from './dropdown.style';


const DropDown = ({ dropdownitems, changehandler, id, setvaluedropdown, valuedropdown, labeltext, ...formfield }) => {
    const classes = useStyles();
    let menuitem;
    if (id == 'statuspackage') {
        menuitem = dropdownitems.map(({ code, description }) => {
            return (
                <MenuItem value={code}>
                    {description}
                </MenuItem>
            );

        });
    }
    else if (id == 'privileges') {
        menuitem = dropdownitems.map(({ id, description }) => {
            return (
                <MenuItem value={id}>
                    {description}
                </MenuItem>
            );

        });
    }
    else if (id == 'modules') {
        menuitem = dropdownitems.map(({ module_id, description }) => {
            return (
                <MenuItem value={module_id}>
                    {description}
                </MenuItem>
            );

        });
    }
    else if (id == 'menus') {
        menuitem = dropdownitems.map(({ menu_id, description }) => {
            return (
                <MenuItem value={menu_id}>
                    {description}
                </MenuItem>
            );

        });
    }
    else if (id == 'features') {
        menuitem = dropdownitems.map(({ feature_id, name }) => {
            return (
                <MenuItem value={feature_id}>
                    {name}
                </MenuItem>
            );

        });
    }

    else {
        menuitem = dropdownitems.map(({ code, description }) => {
            return (
                <MenuItem value={code}>
                    {description}
                </MenuItem>
            );

        });
    }
    console.log(dropdownitems);
    return (
        <div>
            <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id={`${id}-label`}>{labeltext}</InputLabel>
                <Select
                    onChange={changehandler}
                    labelId={`${id}-label`}
                    id={id}
                    value={valuedropdown}
                    label={labeltext}
                    {...formfield}
                >
                    {menuitem}
                </Select>
            </FormControl>
        </div >
    )
}
export default DropDown;