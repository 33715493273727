import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
    inputwrapper: {

    },
    input: {
        display: 'block',
        width: '100%',
        marginTop: '20px',
    }
});
