import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useStyles } from './user.style';
import Input from '../../atoms/input/input';
import { GridOnRounded } from '@material-ui/icons';
import DropDown from '../../atoms/dropdown/dropdown';
import Btn from '../../atoms/button/button';
import dummy from '../../../assets/images/dummy.jpg'
import { getAllUserPrivileges, getAllUsers, getAllUserType, getAllAccountType, createUser, getUserbyId } from '../../../services/userService/userService';
import { hideSplashScreen } from '../../../helpers/splashScreen';
import AppBarDashboard from '../../molecules/appBar/appBar';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { showSuccessSnackbar } from '../../../stores/actions/snackBarActions';
const queryString = require('query-string');

const AddUser = () => {
    const classes = useStyles();
    const nevigate = useHistory();
    const dispatch = useDispatch();
    const parsed = queryString.parse(window.location.search);
    let { id } = parsed;
    const [valueuserType, setvalueuserType] = React.useState('');
    const [valueaccountType, setvalueaccountType] = React.useState('');
    const [valueprivileges, setvalueprivileges] = React.useState("");
    const [userName, setuserName] = React.useState("");
    const [userNameerror, setuserNameerror] = React.useState("");
    const [userNameValid, setuserNameValid] = React.useState(false);
    const [idNumber, setIdNumber] = React.useState("");
    const [idNumbererror, setidNumbererror] = React.useState("");
    const [idNumberValid, setidNumberValid] = React.useState(false);
    const [phoneNo, setphoneNo] = React.useState("");
    const [phoneerror, setphoneerror] = React.useState("");
    const [phonevalidation, setphonevalidation] = React.useState(false);
    const [email, setemail] = React.useState("");
    const [emailerror, setemailerror] = React.useState("");
    const [emailvalidation, setemailvalidation] = React.useState(false);
    const [gender, setgender] = React.useState("");
    const [userDropdownitem, setuserDropdownitem] = React.useState([]);
    const [privilegesitem, setPrivilegesitem] = React.useState([]);
    const [accountDropdownitem, setaccountDropdownitem] = React.useState([]);
    const handlechangeuserType = (event) => {
        debugger;
        setvalueuserType(event.target.value);
    }
    const handlechangeaccountType = (event) => {
        setvalueaccountType(event.target.value);
    }
    const handlechangeprivileges = event => {
        debugger;
        setvalueprivileges(event.target.value);
    }
    const successUserAdd = data => {
        if (data.status) {
            dispatch(showSuccessSnackbar(data.message, "success"));
            nevigate.push("/users");
        }
        else {
            dispatch(showSuccessSnackbar(data.message, "error"));
        }

    }
    const phoneRegExp = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
    const formik = useFormik({
        initialValues: {
            userName: '',
            idNumber: '',
            gender: '',
            phone: '',
            email: '',
            userType: '',
            accountType: '',
            privileges: []

        },
        validationSchema: Yup.object({
            userName: Yup.string().required('Required'),
            idNumber: Yup.number().required('Required'),
            email: Yup.string().email('Invalid email address').required('Required'),
            phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid').required('Required'),
            //gender:Yup.string().required('required'),

        }),
        onSubmit: values => {
            debugger;
            let strvalue = values.privileges.toString();
            values.privileges = strvalue;
            values.gender = gender;
            if (id && id !== '') {
                createUser(values, successUserAdd, true);
            }
            else {
                createUser(values, successUserAdd, false);
            }
        },
    });
    const handlechange = e => {
        if (formik.touched.userName && formik.errors.userName) {
            setuserNameerror(formik.errors.userName);
            setuserNameValid(true);
        }
        else if (formik.touched.idNumber && formik.errors.idNumber) {
            setidNumbererror(formik.errors.idNumber);
            setidNumberValid(true);
        }
        else if (formik.touched.idNumber && formik.errors.idNumber) {
            setidNumbererror(formik.errors.idNumber);
            setidNumberValid(true);
        }
        else if (formik.touched.phone && formik.errors.phone) {
            setphoneerror(formik.errors.phone);
            setphonevalidation(true);
        }
        else if (formik.touched.email && formik.errors.email) {
            setemailerror(formik.errors.email);
            setemailvalidation(true);
        }
        else {
            setuserNameerror("");
            setuserNameValid(false);
            setidNumbererror("");
            setidNumberValid(false);
            setphoneerror("");
            setphonevalidation(false);
            setemailerror("");
            setemailvalidation(false);
        }

    }
    const handlechangegender = event => {
        debugger;
        setgender(event.target.value);
    }
    const handleclick = () => {
        debugger;
    }
    const CallbackDatauser = (data) => {
        debugger;
        setuserDropdownitem(data);
        hideSplashScreen();
    }
    const CallbackDataAccount = (data) => {
        debugger;
        setaccountDropdownitem(data);
        hideSplashScreen();
    }
    const CallbackPrivileges = (data) => {
        setPrivilegesitem(data);
        hideSplashScreen();
    }
    const successGetUser = data => {
        setuserName(data.name);
        setIdNumber(data.id);
        setemail(data.email);
        setphoneNo(data.phoneNo);
        setgender(data.gender);
        setvalueuserType(data.user_type);
        
    }
    useEffect(() => {
        getAllUserType(CallbackDatauser);
        getAllAccountType(CallbackDataAccount);
        getAllUserPrivileges(CallbackPrivileges);
        hideSplashScreen();
        debugger;
        if (id && id !== '') {
            getUserbyId(successGetUser, id);
        }
    }, []);
    return (
        <AppBarDashboard>
            <div>
                <h3>Add User</h3>
                <form onSubmit={formik.handleSubmit}>
                    <Grid container>
                        <Grid container spacing={2} item xs={6}>
                            <Grid item xs={12}>
                                <Input
                                    {...formik.getFieldProps('userName')}
                                    id="userName"
                                    type="text"
                                    labeltext="User Name"
                                    changehandler={handlechange}
                                    helperText={userNameerror}
                                    error={userNameValid}
                                    //value={userName}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Input
                                    {...formik.getFieldProps('idNumber')}
                                    id="idNumber"
                                    type="number"
                                    labeltext="Id Number"
                                    changehandler={handlechange}
                                    helperText={idNumbererror}
                                    error={idNumberValid}
                                    //value={idNumber}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <label>Gender</label>
                                <RadioGroup row aria-label="position" id="gender" {...formik.getFieldProps('gender')} value={gender} onChange={handlechangegender}>
                                    <FormControlLabel
                                        value="male"
                                        control={<Radio color="primary" />}
                                        label="Male"
                                        labelPlacement="start"
                                    />
                                    <FormControlLabel
                                        value="female"
                                        control={<Radio color="primary" />}
                                        label="Female"
                                        labelPlacement="start"
                                    />
                                </RadioGroup>
                            </Grid>

                        </Grid>
                        <Grid container item xs={6}>
                            <Grid item xs={12}>
                                <div className={classes.imagewrapper}>
                                    <img src={dummy} className={classes.imag} />
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <DropDown
                                valuedropdown={valueuserType}
                                setvaluedropdown={setvalueuserType}
                                changehandler={handlechangeuserType}
                                id="userType"
                                labeltext="User Type"
                                dropdownitems={userDropdownitem}
                                {...formik.getFieldProps('userType')}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <DropDown
                                valuedropdown={valueaccountType}
                                setvaluedropdown={setvalueaccountType}
                                changehandler={handlechangeaccountType}
                                id="accountType"
                                labeltext="Account Type"
                                dropdownitems={accountDropdownitem}
                                {...formik.getFieldProps('accountType')}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Input
                                {...formik.getFieldProps('phone')}
                                id="phone"
                                type="text"
                                labeltext="Phone"
                                changehandler={handlechange}
                                helperText={phoneerror}
                                error={phonevalidation}
                               // value={phoneNo}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <DropDown
                                valuedropdown={valueprivileges}
                                setvaluedropdown={setvalueprivileges}
                                changehandler={handlechangeprivileges}
                                id="privileges"
                                labeltext="Privileges"
                                dropdownitems={privilegesitem}
                                {...formik.getFieldProps('privileges')}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Input
                                {...formik.getFieldProps('email')}
                                id="email"
                                type="text"
                                labeltext="Email"
                                changehandler={handlechange}
                                helperText={emailerror}
                                error={emailvalidation}
                                //value={email}
                            />
                        </Grid>
                    </Grid>
                    <Btn
                        type="submit"
                        buttontext="Save"
                        clickhandler={handlechange}
                    />
                </form>
            </div>
        </AppBarDashboard>

    );
};
export default AddUser;

AddUser.defaultProps = {
    id: ''
}