import { makeStyles } from '@material-ui/core/styles';
import { theme } from '../../settings/foundation/foundation'
export const useStyles = makeStyles({
    loginwrapper: {
        [theme.breakpoints.up('sm')]: {
            margin: '4% 35%',
        },
        [theme.breakpoints.down('sm')]: {
            margin: '4%',
        }
    },
    pinfield:{
        paddingTop:'0px !important',
    }
});
