import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { useStyles } from './subscription.style';
import AppBarDashboard from '../../molecules/appBar/appBar';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TabPanel from '../../atoms/tab/tab';


const AddSubscription = () => {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    useEffect(() => {
    }, []);
    return (
        <div>
            <AppBarDashboard>
                <div className={classes.root}>
                    <AppBar position="static">
                        <Tabs
                            variant="fullWidth"
                            value={value}
                            onChange={handleChange}
                            aria-label="nav tabs example"
                        >
                            <LinkTab label="Page One" {...a11yProps(0)} />
                            <LinkTab label="Page Two" {...a11yProps(1)} />
                            <LinkTab label="Page Three" {...a11yProps(2)} />
                        </Tabs>
                    </AppBar>
                    <TabPanel value={value} index={0}>
                        Page One
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        Page Two
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        Page 232323
                    </TabPanel>
                </div>
            </AppBarDashboard>
        </div>
    );
};
export default AddSubscription;

function a11yProps(index) {
    return {
        id: `nav-tab-${index}`,
        'aria-controls': `nav-tabpanel-${index}`,
    };
}

function LinkTab(props) {
    return (
        <Tab
            component="a"
            onClick={(event) => {
                event.preventDefault();
            }}
            {...props}
        />
    );
}