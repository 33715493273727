import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
    packgCard: {
    },
    cardtable: {
         border: '1px solid lightgray',
         padding: '8px',
    },
    EntityCard: {
        marginTop:'15px',
    },
    Subscriptcard: {
        marginTop:'15px',
    },
    beanchtable:{
        marginTop:'15px',
    },
    ActionContainer: {
        float: 'right',
    },
    spacingletter: {
        marginTop:'5px',
    },
    addbtn: {
        marginBottom: '30px',
        backgroundColor: '#5243AA',
        color: '#fff',
        marginRight: '5px',
        textDecoration: 'none',
        '&:hover': {
            marginBottom: '30px',
            backgroundColor: '#5243AA',
            color: '#fff',
        }
    },
    qrcode: {
        textAlign:'center',
    }
});
