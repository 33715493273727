import React from 'react';
import { setSessionStorageItem, getSessionStorageItem } from '../../helpers/utility'


export function getAllFeatures(cb) {
    let URL = window.APP.CONFIGURATION.API + "fetch/features";
    let token = getSessionStorageItem('token');
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
    };
    fetch(URL, requestOptions)
        .then(response => response.json())
        .then(data => {
            debugger;
            console.log(data)
            if (data.status) {
                cb(data.data);
            }
        });
}

export function getAllPackages(cb) {
    let URL = window.APP.CONFIGURATION.API + "fetch/packages";
    let token = getSessionStorageItem('token');
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
    };
    fetch(URL, requestOptions)
        .then(response => response.json())
        .then(data => {
            debugger;
            console.log(data)
            if (data.status) {
                cb(data.data);
            }
        });
}
export function getAllStatus(cb) {
    debugger;
    let URL = window.APP.CONFIGURATION.API + "fetch/sys-code/STS";
    let token = getSessionStorageItem('token');
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
    };
    fetch(URL, requestOptions)
        .then(response => response.json())
        .then(data => {
            debugger;
            console.log(data)
            if (data.status) {
                cb(data.data);
            }
        });
}
export function createFeature(feature, cb) {
    debugger;
    let URL = window.APP.CONFIGURATION.API + "proc/feature";
    let token = getSessionStorageItem('token');
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
        body: JSON.stringify({
            "operation": "C",
            "name": feature.description,
            "price": feature.price,
            "status": feature.status
        })
    };
    fetch(URL, requestOptions)
        .then(response => response.json())
        .then(data => {
            debugger;
            console.log(data)
            if (data.status) {
                cb(data);
            }
        });
}

export function getAllPaymentTypes(cb) {
    let URL = window.APP.CONFIGURATION.API + "fetch/sys-code/PYT";
    let token = getSessionStorageItem('token');
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
    };
    fetch(URL, requestOptions)
        .then(response => response.json())
        .then(data => {
            debugger;
            console.log(data)
            if (data.status) {
                cb(data.data);
            }
        });
}
export function createPackage(packages, cb) {
    debugger;
    let URL = window.APP.CONFIGURATION.API + "proc/package";
    let token = getSessionStorageItem('token');
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
        body: JSON.stringify({
            "operation": "C",
            "name": packages.description,
            "payment_type": packages.paymentType,
            "status": packages.statuspackage
        })
    };
    fetch(URL, requestOptions)
        .then(response => response.json())
        .then(data => {
            debugger;
            console.log(data)
            if (!data.status) {
                cb(data);
            }
            else {
                AddFeaturetoPackage(data.data[0].package_id, packages.features, cb)
            }
        });
}
function AddFeaturetoPackage(featureId, features, cb) {
    debugger;
    let URL = window.APP.CONFIGURATION.API + "proc/package";
    let token = getSessionStorageItem('token');
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
        body: JSON.stringify({
            "operation": "A",
            "package_id": featureId,
            "features": features
        })
    };
    fetch(URL, requestOptions)
        .then(response => response.json())
        .then(data => {
            debugger;
            console.log(data)
            cb(data);
        });
}
