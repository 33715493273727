export const saveToLocalStorage = (state) => {
    try {
        window.localStorage.setItem('state', JSON.stringify(state));
    } catch (e) {
        console.error(e);
    }
};

export const loadFromLocalStorage = () => {
    try {
        const stateStr = window.localStorage.getItem('state');
        return stateStr ? JSON.parse(stateStr) : undefined;
    } catch (e) {
        console.error(e);
        return undefined;
    }
};